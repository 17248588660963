import { webviewLogger } from '@/debug/webview';
import { darkModeState } from '@/jotai';
import { createClient } from '@/supabase/utils/client';
import { captureException } from '@sentry/nextjs';
import { useAtom } from 'jotai';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
export function WebViewCommunicator() {
  const [darkMode] = useAtom(darkModeState);
  const pathname = usePathname();
  const [user, setUser] = useState<{
    status: number;
    user: null | object;
  }>({
    status: SESSION_STATE.LOADING,
    user: null
  });
  useEffect(() => {
    sendWebViewData();
    function sendWebViewData() {
      document.documentElement.className = darkMode ? 'dark' : 'light';
      // iOS , Aodn 메시지를 준다.  로 메지를 준다.
      const data = {
        darkMode: darkMode ? 'dark' : 'light'
      };
      communicateWithAppsWithCallback('themeChanged', data);
    }
  }, [darkMode]);
  useEffect(() => {
    const supabase = createClient();
    supabase.auth.onAuthStateChange((event, session) => {
      if (session === null || session.user === null) {
        setUser({
          status: SESSION_STATE.UNAUTHENTICATED,
          user: null
        });
        communicateWithAppsWithCallback('requestAuthChangeToNative', {
          event,
          user: null
        });
      } else {
        setUser({
          status: SESSION_STATE.AUTHENTICATED,
          user: session.user
        });
        communicateWithAppsWithCallback('requestAuthChangeToNative', {
          event,
          user: session.user
        });
      }
    });
  }, []);
  function sendWebViewStatus(pathname: string, user: {
    status: number;
    user: null | object;
  }) {
    // iOS , Aodn 메시지를 준다.  로 메지를 준다.
    const data = {
      pathname: pathname,
      user: user
    };
    // @ts-ignore
    window.webkit?.messageHandlers?.statusChanged?.postMessage(data);
  }
  useEffect(() => {
    sendWebViewStatus(pathname, user);
  }, [pathname, user]);
  return null;
}

/**
 * 네이티브 앱과의 통신을 처리하는 함수
 * - iOS와 Android 웹뷰 환경에 따라 적절한 메시지 핸들러를 호출합니다.
 * - 데이터를 직렬화하여 네이티브 코드로 전달합니다.
 *
 * @param funcName - 호출할 네이티브 함수 이름
 * @param data - 네이티브 코드로 전달할 데이터 (선택 사항)
 * @param callback - 네이티브 코드에서 호출할 콜백 함수 (선택 사항)
 */
export function communicateWithAppsWithCallback(funcName: string, data?: any, callback?: Function) {
  try {
    webviewLogger('communicateWithAppsWithCallback', {
      funcName,
      data
    });
    const executeCallback = (cb?: Function) => {
      if (cb && typeof cb === 'function') {
        cb();
      }
    };
    if (window.webkit?.messageHandlers?.[funcName]) {
      // iOS 웹뷰 환경
      window.webkit.messageHandlers[funcName].postMessage(data);
      executeCallback(callback);
    } else if (window.webview?.[funcName]) {
      // Android 웹뷰 환경
      const payload = data === undefined ? undefined : typeof data === 'object' ? JSON.stringify(data) : data;
      window.webview[funcName](payload);
      executeCallback(callback);
    }
  } catch (error) {
    console.error(`WebView communication error for "${funcName}":`, error);
    captureException(error); // Sentry에 에러 보고
  }
}
const SESSION_STATE = {
  LOADING: 0,
  UNAUTHENTICATED: 1,
  AUTHENTICATED: 2,
  WAITING: 3
};