'use client';

import { ReactNode } from 'react';
import Logo from '@/public/icon/logo_otu';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
export default function Layout({
  children
}: {
  children: ReactNode;
}) {
  return <div className="welcome-container min-h-screen flex flex-col" data-sentry-component="Layout" data-sentry-source-file="layout.tsx">
            <div className="main flex-grow flex justify-center">
                <div className="max-w-[700px] w-full px-10 content-area">
                    <Header data-sentry-element="Header" data-sentry-source-file="layout.tsx" />
                    {children}
                </div>
            </div>
            <div className="footer flex justify-center py-10 bg-text-color dark:bg-[#1e1e1e] mt-[120px] text-[#949494]">
                <div className="max-w-[700px] w-full px-10">
                    <Footer data-sentry-element="Footer" data-sentry-source-file="layout.tsx" />
                </div>
            </div>
        </div>;
}
function Header() {
  const t = useTranslations();
  const pathname = usePathname();
  const handleBackClick = () => {
    if (window.history.length > 2) {
      window.history.back();
    } else {
      window.location.href = '/welcome';
    }
  };
  return <div data-sentry-component="Header" data-sentry-source-file="layout.tsx">
            <div style={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      marginTop: '43px'
    }}>
                <div>
                    {pathname !== '/welcome' && <button onClick={handleBackClick} className="text-[15px]">
                            {t('navigation.back')}
                        </button>}
                </div>
                <div></div>
                <div>
                    {pathname !== '/signin' && <Link href="/signin" className="text-[15px]">
                            {t('navigation.login')}
                        </Link>}
                </div>
            </div>

            <div className="mt-[70px] flex justify-center">
                <Link href="/" data-sentry-element="Link" data-sentry-source-file="layout.tsx">
                    <Logo width="80" height="39" className="fill-text-color" data-sentry-element="Logo" data-sentry-source-file="layout.tsx"></Logo>
                </Link>
            </div>
        </div>;
}
function Footer() {
  const t = useTranslations();
  return <>
            <div className="text-[13px]">
                <div>{t('home.footer.organization')}</div>
                <div>{t('home.footer.org-number')}</div>
                <div>{t('home.footer.address')}</div>
                <div className="text-white">
                    <Link href="/consent#terms-of-service" data-sentry-element="Link" data-sentry-source-file="layout.tsx">
                        {t('agreement-form.terms-of-service')}
                    </Link>
                    <span> | </span>
                    <Link href="/consent#privacy-policy" data-sentry-element="Link" data-sentry-source-file="layout.tsx">{t('agreement-form.privacy-policy')}</Link>
                </div>
            </div>
        </>;
}