'use client';

import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { darkModeState } from '@/jotai';
import { grey } from '@mui/material/colors';
import { ThemeOptions } from '@mui/system';
export const MuiThemeProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [darkMode] = useAtom(darkModeState);
  const themeOption: ThemeOptions = {
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        // light: '#ff0000',
        main: darkMode ? '#dfe2dd' : '#2c2f7e' // 기본 배경 색상
        // dark: '#00ff00', // 호버시 배경 색상
        // contrastText: darkMode ? '#262626' : '#dfe2dd', // 텍스트 색상
      },
      background: {
        paper: 'var(--bg-color)' // Paper의 배경색
      }
    },
    typography: {
      fontFamily: 'inherit'
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: grey[100],
            '&.Mui-checked': {
              color: grey[200]
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: 'var(--text-color) !important'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            // 버튼의 그림자 제거
            textTransform: 'none',
            '&:hover': {
              boxShadow: 'none' // 호버 상태에서도 그림자 제거
            }
          }
        }
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            borderColor: 'var(--border-color)',
            // ToggleButton의 테두리 색상을 --border-color로 지정
            '&.Mui-selected': {
              borderColor: 'var(--border-color)' // 선택된 상태에서도 테두리 유지
            }
          }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '& svg': {
              color: 'var(--text-color)' // svg 아이콘의 색상을 --text-color로 지정
            },
            borderColor: 'var(--border-color)',
            // ToggleButton의 테두리 색상 지정
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              // 선택된 버튼의 배경색 설정
              '& svg': {
                color: 'var(--text-color)' // 선택된 상태에서도 svg의 색상 유지
              }
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: '15px',
            marginTop: 'calc(env(safe-area-inset-top) + 15px)',
            marginBottom: 'calc(env(safe-area-inset-bottom) + 15px)',
            maxHeight: 'calc(100% - (env(safe-area-inset-top) + 15px) - (env(safe-area-inset-bottom) + 15px))'
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: 'var(--text-color)' // DialogContentText의 색상을 --text-color로 설정
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 15,
            justifyContent: 'center' // 버튼을 가운데 정렬
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            color: 'var(--text-color)' // Typography의 body1 변형의 색상을 --text-color로 설정
          }
        }
      },
      MuiIconButton: {
        // Add style overrides for IconButton
        styleOverrides: {
          root: {
            color: 'var(--text-color)'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // MuiPaper의 root 슬롯 오버라이드
            color: 'var(--text-color)'
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '&.MuiSnackbar-anchorOriginTopCenter': {
              marginTop: 'calc(env(safe-area-inset-top) + 16px)' // 위쪽 안전 영역
            },
            '&.MuiSnackbar-anchorOriginBottomCenter': {
              marginBottom: 'calc(env(safe-area-inset-bottom) + 16px)' // 아래쪽 안전 영역
            },
            '&.MuiSnackbar-anchorOriginTopLeft': {
              marginTop: 'calc(env(safe-area-inset-top) + 16px)' // 위쪽 안전 영역
            },
            '&.MuiSnackbar-anchorOriginBottomLeft': {
              marginBottom: 'calc(env(safe-area-inset-bottom) + 16px)' // 아래쪽 안전 영역
            },
            '&.MuiSnackbar-anchorOriginTopRight': {
              marginTop: 'calc(env(safe-area-inset-top) + 16px)' // 위쪽 안전 영역
            },
            '&.MuiSnackbar-anchorOriginBottomRight': {
              marginBottom: 'calc(env(safe-area-inset-bottom) + 16px)' // 아래쪽 안전 영역
            }
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--bg-color)',
            // Accordion의 배경색
            boxShadow: 'none',
            '&::before': {
              opacity: '1 !important' // ::before의 opacity를 항상 1로 지정
            }
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            shadow: 'none'
          }
        }
      }
    }
  };

  // @ts-ignore
  const theme = createTheme(themeOption);
  useEffect(() => {
    if (darkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-theme');
    }
  }, [darkMode]);
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="MuiThemeProvider" data-sentry-source-file="MuiThemeProvider.tsx">{children}</ThemeProvider>;
};